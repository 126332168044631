import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const SeparationLine = ({
   color,
}) => {

    return (
        <Container disableGutters>
            <Box
                sx={{
                    width: '100%',
                    borderBottom: `1px solid ${color}`
                }}
            >
            </Box>
        </Container>
    )
};

export default SeparationLine;