import {Box, Typography} from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import {strommesGreen, strommesOrange} from "../theme/theme";

const Employee = ({
    name,
    title,
    phone,
    image,
    mail
}) => {

    return (
        <Box>
            <Box>
                <img
                    style={{
                        maxWidth: '100%',
                        maxHeight: 'auto',
                    }}
                    src={image}
                    alt="Bilde av ansatt"
                />
            </Box>
            <Box
                sx={{
                    marginBottom: '1rem'
                }}
            >
                <Typography
                    fontFamily='GT-America-Black'
                    fontSize="1.2rem"
                    style={{
                        fontWeight: 'bold',

                    }}>
                    {name}
                </Typography>
                <Typography>
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                {
                    phone && (
                        <>
                            <a href={`tel:${phone}`}>
                                <PhoneIcon
                                    sx={{
                                        color: strommesGreen,
                                        fontSize: '20px',
                                        marginRight: '6px',
                                    }}
                                />
                            </a>
                            <Typography>
                                <a href={`tel:${phone}`}>
                                    {phone}
                                </a>
                            </Typography>
                        </>
                    )
                }
            </Box>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                {
                    mail && (
                        <>
                            <a href={`mailto:${mail}`}>
                                <EmailIcon
                                    sx={{
                                        color: strommesOrange,
                                        fontSize: '20px',
                                        marginRight: '6px',
                                    }}
                                />
                            </a>
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: 'black'
                                }}
                                href={`mailto:${mail}`}
                            >
                                <Typography>
                                    Send e-post
                                </Typography>
                            </a>
                        </>
                    )
                }
            </Box>
        </Box>
    );
};

export default Employee;