/**
 * Utility function to calculate the animation delay in a grid of images which take.
 *
 * We want the images to get assigned delays so that they are consistent across each row, so that
 * the first, second third etc. image in a row always gets the same delay.
 *
 * Images which takes up many columns will get the same delay as if the had occupied only their
 * first column, and also affect consecutive delays as if there were the same number of single-column images
 * as the number of columns they occupy. As an illustration, consider an image I, which
 * takes up R rows. We want the image after this to get assigned a delay as if it was image
 * number P + R + 1, where P is the number of columns taken up in that row before image I.
 *
 * @param numberOfColumns
 * @param images
 * @param animationStep
 */
const calculateAnimationDelayInGrid = (
    numberOfColumns,
    images,
    animationStep
) => {
    const delays = [];

    const remainingColumns = new Array(numberOfColumns).fill(0);
    let columnNumber = 0;
    let numberInRow = 0;

    for (let image of images) {

        while (remainingColumns[columnNumber] > 0 && columnNumber < numberOfColumns) {
            remainingColumns[columnNumber]--;
            columnNumber++;
        }

        for (let i = 0; i < image.rows - 1; i++) {
            for (let j = columnNumber; j < image.cols + columnNumber - 1; j++) {
                remainingColumns[j]++;
            }
        }

        delays.push(numberInRow * animationStep);

        columnNumber += image.cols;

        if (columnNumber >= numberOfColumns) {
            columnNumber = 0;
            numberInRow = 0;
        }
        else {
            numberInRow++;
        }
    }

    return delays;
};

export default calculateAnimationDelayInGrid;