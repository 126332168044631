import {Container, Grid, Typography} from "@mui/material";
import AppBar from "../components/AppBar";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CoverImage = ({
    video,
    logos,
    menuItems,
    textColor,
    header,
    text,
    darkLogo,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container
            maxWidth={true}
            disableGutters={true}
            sx={{
                height: '100vh',
                width: '100%',
            }}
        >
            <video
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                }}
                autoPlay
                loop
                muted
            >
                <source
                    src={video?.asset?.url}
                    type="video/mp4"
                />
            </video>
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    paddingLeft: isMobile ? '0' : '5%',
                    paddingRight: isMobile ? '0' : '5%',
                    overflow: 'hidden'
                }}
            >
                {
                    menuItems && <AppBar logos={logos} menuItems={menuItems} dark={darkLogo} color={textColor}/>
                }
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        height: '100%'
                    }}
                >
                    <Grid
                        item
                        md={8}
                    >
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                color: textColor?.colorCode || 'white'
                            }}
                            variant='h1'
                        >
                            {header}
                        </Typography>
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                color: textColor?.colorCode || 'white'
                            }}
                        >
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
};

export default CoverImage;