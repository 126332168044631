import * as React from "react";
import {Route, Routes} from "react-router-dom";
import NoMatch from "./NoMatch";

const Main = ({
    logos,
    menuItems,
    pageStructure,
    generalInfo
}) => {

    const LazyLayout = React.lazy(() => import('./Layout'));

    return (
        <Routes>
            {
                pageStructure && pageStructure.map(page =>
                    (
                        <Route
                            static
                            basename={page.subpageSlug?.current || ''}
                            path={page.subpageSlug?.current || ''}
                            element={
                                <React.Suspense fallback={<></>}>
                                    <LazyLayout
                                        logos={logos}
                                        menuItems={menuItems}
                                        page={page}
                                        generalInfo={generalInfo}
                                    />
                                </React.Suspense>
                            }
                        />
                    ))
            }
            <Route path="*" element={<NoMatch pageStructure={generalInfo?.noMatchPageStructure} />} />
        </Routes>
    )
};

export default Main;