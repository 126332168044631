import {Container, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import sanityClient from "../client";
import {strommesBlue, strommesGreen, strommesOrange} from "../theme/theme";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Map from "../components/Map";

const Locations =  ({
    header,
    headerColor,
}) => {

    const [locations, setLocations] = useState(null);
    const [apiKey, setApiKey] = useState(undefined);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "locations" || _type == "technical"]{
                  ...@,
                  "headQuarter": *[_type == "general" && references(^._id)]
                }`
            )
            .then((data) => {
                setLocations(data.filter(item => item._type === 'locations'));
                setApiKey(data.filter(item => item._type === 'technical')[0]?.mapsApiKey);
            })
            .catch(console.error);
    }, []);

    const mainLocation = locations?.find(location => location.headQuarter.length > 0);
    const generalInfo = mainLocation?.headQuarter[0];
    const otherLocations = locations?.filter(location => location.name !== mainLocation.name);

    return (
        <Container>
            <Grid
                container
                direction="column"
            >
                <Container
                    disableGutters={true}
                    maxWidth={false}
                    style={{
                        marginBottom: '3em'
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            color: headerColor,
                            marginBottom: '25px',
                        }}
                    >
                        {header}
                    </Typography>
                    <Grid
                        sx={{
                            borderBottom: `1px solid ${strommesBlue}`,
                            marginBottom: '0.5em'
                        }}
                        item
                    >
                        <Typography variant="h6" color="black">
                            Hovedkontor
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="h6"
                            color="black"
                            sx={{
                                marginBottom: '10px'
                            }}
                        >
                            {mainLocation?.name}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        style={{
                            marginBottom: '2em'
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{
                                marginBottom: {
                                    xs: '20px'
                                }
                            }}
                        >
                            <Grid container
                                  direction="column"
                            >
                                <Grid item>
                                    <Typography>
                                        {generalInfo?.companyName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {mainLocation?.street}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {mainLocation?.place}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                        >
                            <Grid
                                container
                                direction="column"
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex'
                                    }}
                                >
                                    <a href={`tel:${generalInfo?.phone}`}>
                                        <PhoneIcon
                                            sx={{
                                                color: strommesGreen,
                                                fontSize: '20px',
                                                marginRight: '6px',
                                            }}
                                        />
                                    </a>
                                    <Typography>
                                        <a href={`tel:${generalInfo?.phone}`}>
                                            Telefon: {generalInfo?.phone}
                                        </a>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex'
                                    }}
                                >
                                    <a
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black'
                                        }}
                                        href={`mailto:${generalInfo?.email}`}>
                                        <EmailIcon
                                            sx={{
                                                color: strommesOrange,
                                                fontSize: '20px',
                                                marginRight: '6px',
                                            }}

                                        />
                                    </a>
                                    <Typography>
                                        <a href={`mailto:${generalInfo?.email}`}>
                                            E-post: {generalInfo?.email}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            sx={{
                                marginBottom: {
                                    xs: '40px',
                                    md: '0px',
                                },
                            }}
                        >
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            {
                                mainLocation?.geo && (
                                    <Map
                                        lat={mainLocation?.geo?.lat}
                                        lng={mainLocation?.geo?.lng}
                                        apiKey={apiKey}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>
                </Container>
                <Grid
                    sx={{
                        borderBottom: `1px solid ${strommesBlue}`,
                        marginBottom: '0.5em'
                    }}
                    item
                >
                    <Typography variant="h6" color="black">
                        Avdelingskontor
                    </Typography>
                </Grid>
                <Container
                    disableGutters={true}
                    maxWidth={false}
                    style={{
                        marginBottom: '4em'
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            otherLocations?.map(location => (
                                <Grid
                                    item
                                    xs={4}
                                    md={2}
                                    sx={{
                                        marginRight: '2em',
                                        marginTop: {
                                            xs: '15px'
                                        },
                                        marginBottom: {
                                            xs: '10px'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="black"
                                        sx={{
                                            marginTop: '5px',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        {location.name}
                                    </Typography>
                                    <Typography>
                                        {location.street}
                                    </Typography>
                                    <Typography>
                                        {location.place}
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </Grid>
        </Container>
    )
};

export default Locations;