import sanityClient from "./client";
import {useEffect, useState} from "react";
import Main from "./pages/Main";

function App() {

    const [pageStructure, setPageStructure] = useState(null);
    const [menu, setMenu] = useState(null);
    const [logos, setLogos] = useState(null);
    const [generalInfo, setGeneralInfo] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "subpages" || _type == "navMenu" || _type == "general"]{
                    ...@,
                    menuElements[]->{...@, pages[]{...@, subpage->}},
                    contactLinks[]{...@, urlLogo{asset->{url}}},
                    socialMediaLinks[]{...@, urlLogo{asset->{url}}},
                    darkLogo{asset->{url}},
                    lightLogo{asset->{url}},
                    footerLogo{asset->{url}},
                    noMatchPageStructure{
                      ...@,
                    blocks[]{
                        ...@,
                        largeImage{...@, asset->{url}},
                        smallImage{...@, asset->{url}},
                        image{...@, asset->{url}},
                        buttonLink->{subpageSlug},
                        headerColor->,
                        textColor->,
                        backgroundColor->,
                        button{
                            ...@,
                            textColor->,
                            backgroundColor->
                        }
                      }
                    },
                    subpageStructure[]{
                      ...@,
                      coverImage{...@, asset->{url}},
                      video{...@, asset->{url}},
                      smallImage{...@, asset->{url}},
                      employeeLocations[]->,
                      color->,
                      headerColor->,
                      textColor->,
                      backgroundColor->,
                      buttonLink->{subpageSlug},
                      button{
                        ...@,
                        textColor->,
                        backgroundColor->
                      },
                      images[]{...@, textColor->, imageLinkInternal->{subpageSlug}, image{...@, asset->{url}}},
                      blocks[]{
                        ...@,
                        largeImage{...@, asset->{url}},
                        smallImage{...@, asset->{url}},
                        image{...@, asset->{url}},
                        buttonLink->{subpageSlug},
                        headerColor->,
                        textColor->,
                        backgroundColor->,
                        button{
                            ...@,
                            textColor->,
                            backgroundColor->
                        }
                      }
                    },
                    mainOffice->
                }`
            )
            .then((data) => {
                setPageStructure(data.filter(item => item._type === 'subpages'));
                const menuDocument = data.filter(item => item._type === 'navMenu');
                setMenu(menuDocument[0].menuElements.map(menuItem => {
                    return {
                        name: menuItem.displayName,
                        pages: menuItem.pages,
                    }
                }));
                const generalInfoDocument = data.filter(item => item._type === 'general');
                setGeneralInfo(generalInfoDocument[0]);
                setLogos({
                    darkLogo: generalInfoDocument[0]?.darkLogo.asset.url,
                    lightLogo: generalInfoDocument[0]?.lightLogo.asset.url,
                });
            })
            .catch(console.error);
    }, []);

  return (
      <>
          {
              pageStructure && (
                  <Main
                      logos={logos}
                      menuItems={menu}
                      pageStructure={pageStructure}
                      generalInfo={generalInfo}
                  />
              )
          }
      </>
  );
}

export default App;
