import {Box, Typography} from "@mui/material";
import Linked from "../wrappers/Linked";
import CustomPortableText from "../components/CustomPortableText";
import urlBuilder from "../utils/urlBuilder";
import {srcSet, srcSetCroppedImage} from "../utils/imageSrcSet";

const ImageListItem = ({
    image,
    imageLinkInternal,
    imageLinkExternal,
    imageText,
    textAlignment,
    textOnImage,
    textColor,
    backgroundColor,
    left,
    imageSize
}) => {

    const crop = image?.crop;
    let imageSrcSet;
    if (crop) {
        const imgUrl = urlBuilder(image).url();
        imageSrcSet = srcSetCroppedImage(imgUrl);
    }
    else {
        const imgUrl = image?.asset?.url;
        imageSrcSet = srcSet(imgUrl);
    }

    const textAlignmentMap = (alignment) => {
        switch (alignment) {
            case 'Venstre': return 'left';
            case 'Midt': return 'center';
            case 'Høyre': return 'right';
            default: return 'left';
        }
    };

    const imageSizeMap = (size) => {
        switch (size) {
            case 'Liten': return '50%';
            case 'Medium': return '75%';
            case 'Stor': return '100%';
            default: return '100%';
        }
    };

    return (
        <Box
            sx={{
                height: 'fit-content',
                position: 'relative',
                padding: backgroundColor ? '40px' : '0',
                background: backgroundColor?.colorCode,
            }}
        >
            <Box
                sx={{
                    background: backgroundColor?.colorCode,
                    position: 'absolute',
                    zIndex: '-1',
                    height: '100%',
                    top: '0',
                    ...left ? { right: '1vw'} : { left: '1vw' }
                }}
            >

            </Box>
            <Linked
                internalLink={imageLinkInternal?.subpageSlug?.current || (imageLinkInternal == null ? undefined : '')}
                externalLink={imageLinkExternal}
            >
                <img
                    style={{
                        width: imageSizeMap(imageSize),
                        height: 'auto',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                    {...imageSrcSet}
                    loading="lazy"
                    alt="Bilde"
                />
                <Typography
                    fontFamily="GT-America-Black"
                    align={textAlignmentMap(textAlignment)}
                    color={textColor?.colorCode || 'inherit'}
                    sx={{
                        ...textOnImage
                            ?
                            {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '90%',
                                padding: backgroundColor ? '40px' : '0',
                            }
                            : {}
                    }}
                >
                    <CustomPortableText
                        value={imageText}
                    />
                </Typography>
            </Linked>
        </Box>
    )
};

export default ImageListItem;