import {getAnimation} from "../utils/transitions";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";
import urlBuilder from "../utils/urlBuilder";
import {srcSet, srcSetCroppedImage} from "../utils/imageSrcSet";
import Linked from "../wrappers/Linked";
import {Typography} from "@mui/material";
import CustomPortableText from "../components/CustomPortableText";

const ImageGridElement = ({
    image,
    animationDirection,
    animationDelay
}) => {

    const textAlignmentMap = (alignment) => {
        switch (alignment) {
            case 'Venstre': return 'left';
            case 'Midt': return 'center';
            case 'Høyre': return 'right';
            default: return 'left';
        }
    }

    const animation = getAnimation(animationDirection);

    const control = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    const crop = image?.image?.crop;
    let imageSrcSet;
    if (crop) {
        const imgUrl = urlBuilder(image?.image).url();
        imageSrcSet = srcSetCroppedImage(imgUrl);
    }
    else {
        const imgUrl = image?.image.asset?.url;
        imageSrcSet = srcSet(imgUrl);
    }

    const imageLinkInternal = image.imageLinkInternal;

    const element = (
        <Linked
            internalLink={imageLinkInternal?.subpageSlug?.current || (imageLinkInternal == null ? undefined : '')}
            externalLink={image?.imageLinkExternal}
        >
            <img
                style={{
                    width: '100%',
                    height: image?.extendHeight ? '100%' : 'auto'
                }}
                {...imageSrcSet}
                loading="lazy"
                alt="Bilde i rutenett"
            />
            <Typography
                fontFamily="GT-America-Black"
                align={textAlignmentMap(image?.textAlignment)}
                color={image?.textColor?.colorCode || 'inherit'}
                sx={{
                    ...image?.textOnImage
                        ?
                        {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }
                        : {}
                }}
                variant="body1"
                variantMapping={{
                    body1: 'div'
                }}
            >
                <CustomPortableText
                    value={image?.imageText}
                />
            </Typography>
        </Linked>
    );

    return (
        animation
            ?
            (
                <motion.div
                    ref={ref}
                    variants={animation(animationDelay)}
                    initial="hidden"
                    animate={control}
                    style={{
                        width: '100%',
                        height: image?.extendHeight ? '100%' : 'auto'
                    }}
                >
                    {element}
                </motion.div>
            )
            : element
    );
};

export default ImageGridElement;