import {srcSet, srcSetCroppedImage} from "../utils/imageSrcSet";
import urlBuilder from "../utils/urlBuilder";
import Box from "@mui/material/Box";

const DoubleImage = ({
    largeImage,
    smallImage,
}) => {

    const cropLargeImage = largeImage?.crop;
    let largeImageSrcSet;
    if (cropLargeImage) {
        const imgUrl = urlBuilder(largeImage).url();
        largeImageSrcSet = srcSetCroppedImage(imgUrl);
    }
    else {
        const imgUrl = largeImage?.asset?.url;
        largeImageSrcSet = srcSet(imgUrl);
    }

    const cropSmallImage = smallImage?.crop;
    let smallImageSrcSet;
    if (cropSmallImage) {
        const imgUrl = urlBuilder(smallImage).url();
        smallImageSrcSet = srcSetCroppedImage(imgUrl);
    }
    else {
        const imgUrl = smallImage?.asset?.url;
        smallImageSrcSet = srcSet(imgUrl);
    }

    return (
        <Box>
            <Box
                sx={{
                    maxWidth: {
                        xs: '100%',
                        md: '80%'
                    }
                }}
            >
                <img
                    {...largeImageSrcSet}
                    style={{
                        maxWidth: '100%'
                    }}
                    alt="Stort bilde"
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent:'end',
                    width: '100%'
                }}
            >
                <img
                    {...smallImageSrcSet}
                    style={{
                        maxWidth: '40%',
                        marginTop: '-15%',
                    }}
                    alt="Lite bilde"
                />
            </Box>
        </Box>
    );
};

export default DoubleImage;