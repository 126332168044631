import {strommesBlue} from "../theme/theme";
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStylesTextField = makeStyles((theme) => ({
    root: {
        width: '80%',
        '&. MuiTextField-root': {
            width: '80%'
        },
        '& .MuiInputBase-input': {
            padding: '4px',
            border: `2px solid ${strommesBlue}`,
            width: '100%'
        }
    },
}));

const useStylesTextArea = makeStyles((theme) => ({
    root: {
        border: 0,
        '&:hover': {

        },
        width: '80%',
        '&. MuiTextField-root': {
            width: '80%',
            fontSize: '2rem',
        },
        '& .MuiInputBase-multiline': {
            padding: '4px',
            border: `2px solid ${strommesBlue}`,
            width: '100%'
        }
    },
}));

export const StyledTextField = (props) => {

    const classes = useStylesTextField();

    return (
        <TextField
            className={classes.root}
            {...props}
        />
    );
};

export const StyledTextArea = (props) => {

    const classes = useStylesTextArea();

    return (
        <TextField
            multiline
            rows={8}
            variant="standard"
            className={classes.root}
            InputProps={{
                disableUnderline: true
            }}
            {...props}
        />
    );
}