import {PortableText} from '@portabletext/react'
import {Container} from "@mui/material";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";
import { getAnimation } from '../utils/transitions';

const TextBox = ({
     text,
     backgroundColor,
     textColor,
     animationDirection
 }) => {

    const animation = getAnimation(animationDirection);

    const control = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    const element = (
        <Container
            sx={{
                padding: '25px',
                backgroundColor: backgroundColor,
                color: textColor
            }}
        >
            <PortableText
                value={text}
            />
        </Container>
    );

    return animation ? (
            <motion.div
                ref={ref}
                variants={animation(0)}
                initial="hidden"
                animate={control}
            >
                {element}
            </motion.div>
        )
        : (
            element
        )
    ;
};

export default TextBox;