import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import gTAmericaCompressedRegular from '../fonts/GT-America-Compressed-Regular.woff2';
import gTAmericaCompressedBlack from '../fonts/GT-America-Compressed-Black.woff2';
import gTAmericaStandardRegular from '../fonts/GT-America-Standard-Regular.woff2';
import gTAmericaStandardBold from '../fonts/GT-America-Standard-Bold.woff2';

export const strommesOrange = '#E87257';
export const strommesGreen = '#5AA095';
export const strommesLightGreen = '#82D5BA';
export const strommesBlue = '#375172';
export const strommesBlack = '#000000';
export const pink = '#F2ACB9';

export const strommesWhite = '#FFFFFF';

let theme = createTheme({
    breakpoints: {
        values: {
            xxs: 0, // small phone
            xs: 300, // phone
            sm: 600, // tablets
            md: 900, // small laptop
            lg: 1200, // desktop
            xl: 1536 // large screens
        }
    },
    typography: {
        fontFamily: [
            'GT-America',
            'GT-America-Bold',
            'GT-America-Compressed',
            'GT-America-Black',
            'sans-serif'
        ].join(','),
        h1: {
            color: strommesWhite,
            fontFamily: 'GT-America-Black',
            fontSize: '90px'
        },
        h3: {
            color: strommesOrange,
            fontFamily: 'GT-America-Black',
            marginBottom: '2rem',
        },
        h4: {
            color: strommesGreen,
            fontFamily: 'GT-America-Black',
            marginBottom: '1rem',
            fontWeight: 'bold',
        },
        h5: {
            fontFamily: 'GT-America-Black',
        },
        h6: {
            color: strommesWhite,
            fontFamily: 'GT-America-Black',
        },
        subtitle1: {
            fontFamily: 'GT-America-Black',

            lineHeight: 1,
        }
    },
    overrides: {
        MuiInputBase: {
            input: {
                backgroundColor: "#123456",
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px'
                },
            },
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'GT-America';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('GT-America'), local('GT-America-Standard-Regular'), url(${gTAmericaStandardRegular}) format('woff2');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                  font-family: 'GT-America-Bold';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('GT-America'), local('GT-America-Standard-Bold'), url(${gTAmericaStandardBold}) format('woff2');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                  font-family: 'GT-America-Compressed';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('GT-America'), local('GT-America-Compressed-Regular'), url(${gTAmericaCompressedRegular}) format('woff2');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                  font-family: 'GT-America-Black';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('GT-America'), local('GT-America-Compressed-Black'), url(${gTAmericaCompressedBlack}) format('woff2');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                a {
                  text-decoration: none;
                  color: inherit;
                }
                h1 {
                  font-family: GT-America-Black;
                }
                h2 {
                  font-family: GT-America-Black;
                }
                h3 {
                  font-family: GT-America-Black;
                }
                h4 {
                  font-family: GT-America;
                }
                h5 {
                  font-family: GT-America;
                }
                h6 {
                  font-family: GT-America;
                }
                video::-webkit-media-controls-timeline {
                    display: none;
                }
                video::-webkit-media-controls {
                    display: none;
                }
            `,
        },
    },
});

theme.spacing(10);
theme = responsiveFontSizes(theme);

export default theme;