import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useState} from "react";
import {pink, strommesWhite} from "../theme/theme";
import {NavLink} from "react-router-dom";
import {Typography} from "@mui/material";
import MobileMenuExpandableList from "../components/MobileMenuExpandableList";

const MobileMenuItem = ({
    displayName,
    items,
}) => {

    const [open, setOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setOpen(open);
    };

    return (
        <Box
            role="presentation"
            sx={{
                backgroundColor: pink
            }}
        >
            {
                items?.length > 1
                ?
                    (
                        <>
                            <Button
                                style={{
                                    maxWidth: '100%',
                                    justifyContent: 'flex-start',
                                    border: 'none',
                                    padding: '0'
                                }}
                                onClick={toggleDrawer(!open)}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        textTransform: 'none',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        color: strommesWhite
                                    }}
                                >
                                    {displayName}
                                </Typography>
                            </Button>
                            <MobileMenuExpandableList
                                open={open}
                                items={items}
                            />
                        </>
                    )
                :
                    items?.length === 1 && (
                        <NavLink
                            to={`/${items[0]?.subpage?.subpageSlug?.current || ''}`}
                            style={{
                                textDecoration: 'none',
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    color: strommesWhite
                                }}
                            >
                                {displayName}
                            </Typography>
                        </NavLink>
                    )
            }
        </Box>
    );
}

export default MobileMenuItem;