import {Box, List, ListItem, Menu, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import uuid from 'react-uuid';
import {strommesOrange, strommesBlack, strommesWhite} from "../theme/theme";
import {NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    menuItemStyle: {
        paddingLeft: '15px',
        paddingRight: '20px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    coverBoxStyle: {
        position: 'relative',
    },
    menuItemHoverStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        '&:hover': {
            backgroundColor: strommesOrange,
            opacity: 0.4,
        }
    },
    arrowBoxStyle: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        top: '-10px',
        transform: 'rotate(45deg)',
        backgroundColor: 'white',
        left: '20px',
    }
}));

const DropDownMenu = ({
    displayName,
    items,
    color
}) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(Boolean(anchorEl));

    useEffect(() => {
        const onScroll = () => {
            setOpen(false);
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <Box
            component="span"
            sx={{
                ":hover": {
                    cursor: 'pointer'
                }
            }}
        >
            <List
                component="nav"
                aria-label="Dropdown menu"
            >
                <ListItem
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                    sx={{
                        padding: 0
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: color?.colorCode || strommesWhite
                        }}
                    >
                        {
                            displayName
                        }
                    </Typography>
                </ListItem>
            </List>
            <Menu
                id="dropdown-menu"
                disableScrollLock
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'dropdown-menu',
                }}
                PaperProps={{
                    style: {
                        marginTop: '15px',
                        borderRadius: 0,
                        borderBottom: `4px solid ${strommesOrange}`,
                        boxShadow: 'none',
                        overflow: 'visible',
                    }
                }}
            >
                <Box
                    className={classes.arrowBoxStyle}
                />
                {items?.map((item, index) => (
                    <li
                        key={uuid()}
                    >
                        <NavLink
                            to={`/${item.subpage?.subpageSlug?.current || ''}`}
                        >
                            <Box
                                className={classes.coverBoxStyle}
                            >
                                <Box
                                    className={
                                        classes.menuItemHoverStyle
                                    }
                                />
                                <Typography
                                    variant="h6"
                                    color={strommesBlack}
                                    fontFamily="GT-America"
                                    fontSize={16}
                                    className={classes.menuItemStyle}
                                >
                                    {
                                        item.displayName
                                    }
                                </Typography>
                            </Box>
                        </NavLink>
                    </li>
                ))}
            </Menu>
        </Box>
    );
};

export default DropDownMenu;