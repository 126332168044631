import {Container, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import sanityClient from "../client";
import {strommesBlue} from "../theme/theme";
import Employee from "../components/Employee";

const EmployeeCard = ({
    locations
}) => {

    const [employees, setEmployees] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "employees"]{
                  ...@,
                  employeeLocation->,
                  employeeDepartment->,
                  title->,
                  image{asset->{url}}
                }`
            )
            .then((data) => {
                data?.sort((a, b) => {
                    const employeeSortOrderA = a?.sortOrder || 0;
                    const employeeSortOrderB = b?.sortOrder || 0;
                    const departmentSortOrderA = a?.employeeDepartment?.sortOrder || 0;
                    const departmentSortOrderB = b?.employeeDepartment?.sortOrder || 0;
                    if (departmentSortOrderA > departmentSortOrderB) {
                        return 1;
                    }
                    else if (departmentSortOrderA < departmentSortOrderB) {
                        return -1;
                    }
                    else if (employeeSortOrderA > employeeSortOrderB) {
                        return 1;
                    }
                    else if (employeeSortOrderA < employeeSortOrderB) {
                        return -1;
                    }
                    return 0;
                });
                setEmployees(data);
            })
            .catch(console.error);
    }, []);

    const byLocationByDepartment = employees?.reduce(
        (prev, cur) => {
            // Skip locations that are not in the input to the component
            if (!locations.find(location => location.name === cur.employeeLocation.name)) {
                return prev;
            }
            // Is the location of the employee new?
            if(!prev[cur.employeeLocation.name]) {
                return {
                    ...prev,
                    [cur.employeeLocation.name]: {
                        [cur.employeeDepartment.departmentName]:[cur]
                    }
                }
            }
            // Is the department but not the location new?
            else if(prev[cur.employeeLocation.name] &&
                !prev[cur.employeeLocation.name][cur.employeeDepartment.departmentName]) {
                return {
                    ...prev,
                    [cur.employeeLocation.name]: {
                        ...prev[cur.employeeLocation.name],
                        [cur.employeeDepartment.departmentName]:[cur]
                    }
                }
            }
            // Neither the location nor the department is new. Insert the employee at its place.
            else {
                return {
                    ...prev,
                    [cur.employeeLocation.name]: {
                        ...prev[cur.employeeLocation.name],
                        [cur.employeeDepartment.departmentName]:[...prev[cur.employeeLocation.name][cur.employeeDepartment.departmentName], cur]
                    }
                }
            }
        },
        {}
    );

    const existingLocations = Object.keys(byLocationByDepartment || {});

    return (
        <Container>
            {
                byLocationByDepartment &&
                existingLocations.map(location => {
                    return (
                        <Grid
                            container
                            sx={{
                                justifyContent: 'center',
                            }}
                        >
                            <Grid
                                md={12}
                                sx={{
                                    marginBottom: '5em',
                                    width: '100%',
                                }}
                                item
                            >
                                <Grid
                                    container
                                    direction="column"
                                >
                                    {
                                        (Object.keys(byLocationByDepartment[location])).map(dep => {
                                            return (
                                                <>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            borderBottom: `1px solid ${strommesBlue}`,
                                                            marginBottom: '1rem',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            color="black"
                                                        >
                                                            {dep}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        sx={{
                                                            paddingBottom: '56px',
                                                        }}
                                                        spacing={6}
                                                        rowSpacing={6}
                                                    >
                                                        {
                                                            byLocationByDepartment[location][dep].map(employee => {
                                                                return (
                                                                    <Grid
                                                                        xs={6}
                                                                        md={4}
                                                                        item
                                                                    >
                                                                        <Employee
                                                                            name={employee.name}
                                                                            title={employee.title.jobTitleName}
                                                                            phone={employee.phoneNumber}
                                                                            image={employee.image.asset.url}
                                                                            mail={employee.email}
                                                                        />
                                                                    </Grid>
                                                                );
                                                            })
                                                        }
                                                    </Grid>
                                                </>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })
            }
        </Container>
    )
};

export default EmployeeCard;