import {Container, Grid, Typography} from "@mui/material";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import HorizontalLine from "../components/HorizontalLine";
import {validator, createValidationSchema} from "../utils/formValidator";
import {StyledTextField, StyledTextArea} from "../components/TextInput"
import {pink} from "../theme/theme";
import client from "../client";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";

const ContactForm = ({
    header,
    headerColor,
    fields,
}) => {

    const [messages, setMessages] = useState([]);

    useEffect(() => {
        client
            .fetch(
                `*[_type == "message"]{
                    ...@,
                    }`
            )
            .then((data) => {
                setMessages(data.sort((m1, m2) => { return new Date(m2._createdAt) - new Date(m1._createdAt) }));
                })
            .catch(console.error);
    }, [messages]);


    const [messageSent, setMessageSent] = useState(false);
    const [errorSendingMessage, setErrorSendingMessage] = useState(false);

    let formSchemaShape = createValidationSchema(fields);

    formSchemaShape = {
        ...formSchemaShape,
        text: Yup.string().required('Fyll inn melding!')
    }

    const contactFormSchema = Yup.object().shape(
        formSchemaShape
    );

    const mappedFields = validator(fields);

    const onSubmit = (values) => {
        client.create({
            _type: 'message',
            name: values?.name || '',
            email: values?.email || '',
            phone: values?.phone || '',
            company: values?.company || '',
            message: values?.text || '',
        })
            .then(() => { setErrorSendingMessage(false); setMessageSent(true)}, () => { setErrorSendingMessage(true); setMessageSent(true)})
    };

    return (
        <Container>
            <Typography
                variant="h3"
                sx={{
                    color: headerColor
                }}
            >
                {
                    header
                }
            </Typography>
            <HorizontalLine />
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    text: '',
                }}
                validationSchema={contactFormSchema}
                onSubmit={onSubmit}
            >
                {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <Form
                        noValidate
                    >
                        <Grid
                            container
                            direction="row"
                        >
                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <Grid
                                    container
                                    direction="column"
                                >
                                    {
                                        mappedFields && mappedFields.map(field => {
                                            return (
                                                <Grid
                                                    item
                                                    style={{
                                                        marginBottom: '10px'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        color="black"
                                                        style={{
                                                            marginBottom: '4px'
                                                        }}
                                                    >
                                                        {
                                                            field.name
                                                        }
                                                        {
                                                            field.isMandatory
                                                                ? '*'
                                                                : ''
                                                        }
                                                    </Typography>
                                                    <Field
                                                        type={field.type}
                                                        name={field.type}
                                                        as={StyledTextField}
                                                    />
                                                    <Box
                                                        sx={{
                                                            minHeight: '1em',
                                                            maxHeight: '1em'
                                                        }}
                                                    >
                                                        {
                                                            touched[field.type] && (
                                                                <Typography
                                                                    color="red"
                                                                    sx={{
                                                                        fontSize: '0.9em'
                                                                    }}
                                                                >
                                                                    {
                                                                        errors[field.type]
                                                                    }
                                                                </Typography>
                                                            )
                                                        }
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                            >
                                <Grid
                                    container
                                    direction="column"
                                >
                                    <Grid
                                        item
                                        style={{
                                            marginBottom: '5px'
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="black"
                                            style={{
                                                marginBottom: '4px'
                                            }}
                                        >
                                            Melding*
                                        </Typography>
                                        <Field
                                            type="text"
                                            name="text"
                                            as={StyledTextArea}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                              sx={{
                                                  minHeight: '1.5em',
                                                  maxHeight: '100%',
                                                  width: '100%'
                                              }}
                                        >
                                            <Grid item>
                                                <Box>
                                                    {
                                                        touched['text'] && (
                                                            <Typography
                                                                color="red"
                                                                sx={{
                                                                    fontSize: '0.9em',
                                                                }}
                                                            >
                                                                {
                                                                    errors['text']
                                                                }
                                                            </Typography>
                                                        )
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            minHeight: {
                                                xs: '3.5em'
                                            }
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            sx={{
                                                width: '80%',
                                                justifyContent: 'end',
                                            }}
                                        >
                                            <Grid item>
                                                {
                                                    messageSent
                                                        ? !errorSendingMessage
                                                            ? (
                                                                <Box>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        style={{
                                                                            fontSize: '1rem'
                                                                        }}
                                                                    >
                                                                        Takk for din melding!
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                            : (
                                                            <Box>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    style={{
                                                                        color: 'red',
                                                                        fontSize: '1rem'
                                                                    }}
                                                                >
                                                                    Noe gikk galt ved sending!
                                                                </Typography>
                                                            </Box>
                                                        )
                                                        : (
                                                            <button
                                                                type="submit"
                                                                disabled={isSubmitting || messageSent}
                                                                style={{
                                                                    backgroundColor: "black",
                                                                    borderRadius: '4px',
                                                                    color: pink,
                                                                    border: 'none',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    opacity: isSubmitting ? '0.5' : '1'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    style={{
                                                                        fontSize: '2rem'
                                                                    }}
                                                                >
                                                                    Send hilsen!
                                                                </Typography>
                                                            </button>
                                                        )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                    )
                }
            </Formik>
            <Typography variant="h1" color="black" sx={{marginBottom: '20px'}}>
                Sendte hilsener!
            </Typography>
            {
                messages.map(message => {
                    return (
                        <Container
                            disableGutters
                            sx={{
                                marginTop: '20px',
                                marginBottom: '20px',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: pink
                                }}
                            >
                                <Typography
                                    variant="h2"
                                >
                                    {
                                        message.message
                                    }
                                </Typography>
                                <Typography
                                    variant="h3"
                                    color="black"
                                >
                                    {
                                        message.name && (
                                            <>
                                                Hilsen:&nbsp;
                                                {
                                                    message.name
                                                }
                                            </>
                                        )
                                    }
                                </Typography>
                                <Typography>
                                    Tid:
                                    {
                                        new Date(message._createdAt).toString()
                                    }
                                </Typography>
                            </Box>
                        </Container>
                    )
                })
            }
        </Container>
    );
};

export default ContactForm;