import React from "react";
import {NavLink} from "react-router-dom";

const linked = ({internalLink, externalLink, ...props}) => {
    if (internalLink !== undefined) {
        return (
            <NavLink
                to={`/${internalLink}`}
                style={{
                    textDecoration: 'none'
                }}
            >
                {props.children}
            </NavLink>
        );
    }
    else if (externalLink !== undefined) {
        return (
            <a
                href={externalLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                {props.children}
            </a>
        );
    }
    return props.children;
};

export default linked;