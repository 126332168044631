import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import {pink, strommesWhite} from "../theme/theme";
import MobileMenuItem from "../components/MobileMenuItem";
import Box from "@mui/material/Box";
import {useLocation} from "react-router-dom";

const MobileMenu = ({
    items,
    color,
}) => {

    const [open, setOpen] = useState(false);

    let location = useLocation();

    useEffect(() => {
       setOpen(false);
    }, [location]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    return (
        <>
            <Button onClick={toggleDrawer(true)}>
                <MenuIcon
                    style={{
                        color: color?.colorCode || strommesWhite,
                        fontSize: '3em'
                    }}
                />
            </Button>
                <Drawer
                    anchor={'top'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    sx={{
                        maxWidth: '100%',
                    }}
                >
                    {
                        items?.map(item => (
                                <MobileMenuItem
                                    displayName={item?.name}
                                    items={item?.pages}
                                />
                            )
                        )
                    }
                    <Box
                        sx={{
                            backgroundColor: pink,
                            paddingLeft: '18px',
                        }}
                    >
                    </Box>
            </Drawer>
        </>
    );
}

export default MobileMenu;