import {NavLink} from "react-router-dom";
import {Typography} from "@mui/material";
import uuid from 'react-uuid';
import {strommesWhite} from "../theme/theme";
import DropDownMenu from "../components/DropDownMenu";

const Menu = ({
    items,
    color,
}) => {

    return (
        <nav
            style={{
                display: 'flex',
                height: '100%',
                alignItems: 'center'
            }}
        >
            <ul style={{
                listStyleType: 'none',
                display: 'inline-block'
            }}
            >
                {
                    items?.map(item => {
                        // Show extended menu if the number of pages are more than 1
                        const slug = item?.pages?.length === 1
                            ? item.pages[0].subpage.subpageSlug?.current
                            : null;

                        if (slug !== null) {
                            return (
                                <li
                                    style={{
                                        display: 'inline-block',
                                        paddingRight: '2em'
                                    }}
                                    key={uuid()}
                                >
                                    <NavLink
                                        to={`/${slug}`}
                                        style={{
                                            textDecoration: 'none'
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: color?.colorCode || strommesWhite
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </NavLink>
                                </li>
                            );
                        }
                       else {
                           return (
                               <li
                                   style={{
                                       display: 'inline-block',
                                       paddingRight: '2em'
                                   }}
                                   key={uuid()}
                               >
                                   <DropDownMenu
                                       displayName={item.name}
                                       items={item.pages}
                                       color={color}
                                   />
                               </li>
                               );
                        }
                    }
                    )
                }
            </ul>
        </nav>
    )
};

export default Menu;