import * as React from 'react';
import {strommesWhite} from "../theme/theme";
import {NavLink} from "react-router-dom";
import {Typography} from "@mui/material";

const MobileMenuExpandableList = ({
    open,
    items,
}) => {

    return (
        <ul
            style={{
                display: open ? '' : 'none',
                marginTop: '0px',
                ...open
                ?
                    {
                        transform: 'translateY(0%)',
                        transition: '0.5s ease',
                        opacity: 1
                    }
                :
                    {
                        transform: 'translateY(-100%)',
                        transition: '0.5s ease',
                        opacity: 0
                    }
            }}
        >
            {
                items?.map(item => (
                        <li
                            style={{
                                listStyle: 'none',
                                maxWidth: '100%',
                                padding: '0'
                            }}
                        >
                            <NavLink
                                to={`/${item?.subpage?.subpageSlug?.current || ''}`}
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: strommesWhite
                                    }}
                                >
                                    {item?.displayName}
                                </Typography>
                            </NavLink>
                        </li>
                    )
                )
            }
        </ul>
    );
}

export default MobileMenuExpandableList;