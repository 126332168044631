const ScrambledHeading = ({
  text
}) => {

    const rotateDeg = [10, -10, 0];
    const translateY = [-3, 3, 0];

    return (
        <>
            {
                text.split('').map((letter, index) => {
                        return (
                            letter === ' '
                            ? (<span>&nbsp;&nbsp;</span>)
                            : (
                                <span
                                        style={{
                                            transform: `rotate(${rotateDeg[index%rotateDeg.length]}deg) translate(0px,${translateY[index%translateY.length]}px)`,
                                            display: 'inline-block'
                                        }}
                                >
                                    {letter}
                                </span>
                            )
                        );
                    }
                )
            }
        </>
    )
};

export default ScrambledHeading;