import {PortableText} from '@portabletext/react'
import {Box} from "@mui/material";

const TextBox = ({
    text,
    backgroundColor,
    textColor,
}) => {
    return (
        <Box
            sx={{
                padding: '25px',
                backgroundColor: backgroundColor,
                color: textColor
            }}
        >
            <PortableText
                value={text}
            />
        </Box>
    );
};

export default TextBox;