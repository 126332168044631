import Container from '@mui/material/Container';
import CardFactory from "../cards/CardFactory";
import {Helmet} from "react-helmet";

const NoMatch = ({
  pageStructure
 }) => {
    return (
        <Container
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <>
                { CardFactory(pageStructure, null, null, null) }
            </>
        </Container>
    )
};

export default NoMatch;