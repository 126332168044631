import TextWithButton from "./TextWithButton";
import TextBox from "./TextBox";
import DoubleImage from "./DoubleImage";
import ExtendedImage from "./ExtendedImage";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";
import {getAnimation} from "../utils/transitions";

const Block = (block, index = 0, animationDirection = undefined) => {

    const control = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    const animate = (element, animationDirection) => {
        const animation = getAnimation(animationDirection);
        if (animation) {
            return (
                <motion.div
                    ref={ref}
                    variants={animation(0)}
                    initial="hidden"
                    animate={control}
                >
                    {element}
                </motion.div>
            );
        }
        else {
            return element;
        }
    }

    let blockElement;

    switch (block._type) {
        case 'textWithButton': {
            blockElement =
                (
                    <TextWithButton
                        header={block.header}
                        headerColor={block?.headerColor?.colorCode || 'black'}
                        text={block.text}
                        buttonText={block?.button?.text || ''}
                        buttonTextColor={block?.button?.textColor?.colorCode || 'inherit'}
                        buttonBackgroundColor={block?.button?.backgroundColor?.colorCode || 'inherit'}
                        buttonSlug={block.buttonLink?.subpageSlug?.current || (block.buttonLink == null ? undefined : '')}
                        buttonLinkExternal={block.buttonLinkExternal}
                    />
                );
            break;
        }
        case 'doubleImage': {
            blockElement =
                (
                    <DoubleImage
                        largeImage={block.largeImage}
                        smallImage={block.smallImage}
                    />
                );
            break;
        }
        case 'textBox': {
            blockElement =
                (
                    <TextBox
                        text={block.text}
                        backgroundColor={block.backgroundColor?.value || 'inherit'}
                        textColor={block.textColor?.value || 'inherit'}
                    />
                );
            break;
        }
        case 'extendedImage': {
            blockElement =
                (
                    <ExtendedImage
                        image={block.image}
                        imageLinkInternal={block.imageLinkInternal}
                        imageLinkExternal={block.imageLinkExternal}
                        imageText={block.imageText}
                        textAlignment={block.textAlignment}
                        textOnImage={block.textOnImage}
                        textColor={block.textColor}
                        backgroundColor={block.backgroundColor}
                        left={index === 0}
                        imageSize={block.imageSize}
                    />
                );
            break;
        }
        default: blockElement = null;
    }
    return animate(blockElement, animationDirection);
};

export default Block;