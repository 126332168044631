const fadeFromCenter = (delay) => { return {
    visible: { opacity: 1, scale: 1, transition: { duration: 1.2, type: "tween", delay } },
    hidden: { opacity: 0, scale: 1, },
} };

const fadeFromBottom = (delay) => { return {
    visible: { opacity: 1, y: 0, transition: { duration: 0.9, type: "tween", delay, ease: "anticipate" } },
    hidden: { opacity: 0, y: 80},
} };

const fadeFromTop = (delay) => { return {
    visible: { opacity: 1, y: 0, transition: { duration: 0.9, type: "tween", delay, ease: "anticipate" } },
    hidden: { opacity: 0, y: -80},
} };

const fadeFromRight = (delay) => { return {
    visible: { opacity: 1, x: 0, transition: { duration: 0.9, type: "tween", delay, ease: "anticipate" } },
    hidden: { opacity: 0, x: 100},
} };

const fadeFromLeft = (delay) => { return {
    visible: { opacity: 1, x: 0, transition: { duration: 0.9, type: "tween", delay, ease: "anticipate" } },
    hidden: { opacity: 0, x: -100},
} };

export const getAnimation = (name) => {
    switch(name?.toLowerCase()) {
        case 'senter': {
            return fadeFromCenter;
        }
        case 'bunn': {
            return fadeFromBottom;
        }
        case 'topp': {
            return fadeFromTop;
        }
        case 'høyre': {
            return fadeFromRight;
        }
        case 'venstre': {
            return fadeFromLeft;
        }
        default: {
            return undefined;
        }
    }
}