import {Container} from "@mui/material";
import ReactPlayer from "react-player";

const Video = ({
   url
}) => {
    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {
                url && (
                    <ReactPlayer
                        url={url}
                        muted
                        controls
                    />
                )
            }
        </Container>
    )
};

export default Video;