export const srcSet = (image) => {
    return {
        src: `${image}?fit=crop&auto=format`,
        srcSet: `${image}?fit=crop&auto=format&dpr=2 2x`,
    };
};

export const srcSetCroppedImage = (image) => {
    return {
        src: `${image}&fit=crop&auto=format`,
        srcSet: `${image}&fit=crop&auto=format&dpr=2 2x`,
    };
};