import {Container, ImageList, ImageListItem, Typography} from "@mui/material";
import ScrambledHeading from "../components/ScrambledHeading";
import imageGapMapping from "../utils/imageGapMapping";
import ImageGridElement from "../blocks/ImageGridElement";
import calculateAnimationDelayInGrid from '../utils/calculateAnimationDelayInGrid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ImageGrid = ({
    header,
    headerColor,
    scrambleHeading,
    images,
    numberOfColumns,
    imageGap,
    animationDirection
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const imageGapValue = imageGapMapping(imageGap);
    const headerElement = (
        <Typography
            variant="h3"
            align="center"
            sx={{
                color: headerColor
            }}
        >
            {
                scrambleHeading
                    ? <ScrambledHeading text={header} textColor={headerColor} />
                    : header
            }
        </Typography>
    );

    const animationDelays = calculateAnimationDelayInGrid(
        numberOfColumns,
        images.map(image => {
            return {
                rows: image?.rows || 1,
                cols: image?.cols || 1
            }
        }),
        0.12
    );

    return (
        <Container>
            {
                header && (
                    headerElement
                )
            }
            <ImageList
                variant="quilted"
                cols={
                    (isMobile && numberOfColumns > 3) ? numberOfColumns/2 : numberOfColumns

                }
                gap={isMobile ? 0 : imageGapValue}
                sx={{
                    overflowY: 'visible',
                }}
            >
                {
                    images?.map((image, index) => (
                        <ImageListItem
                            key={index}
                            cols={image?.cols || 1}
                            rows={image?.rows || 1}
                            sx={{
                                height: image?.extendHeight ? '100%' : 'fit-content',
                                position: 'relative'
                            }}
                        >
                            <ImageGridElement
                                image={image}
                                animationDirection={animationDirection}
                                animationDelay={animationDelays?.length <= index ? 0 : animationDelays[index]}
                            />
                        </ImageListItem>
                    ))
                }
            </ImageList>
        </Container>
    )
};

export default ImageGrid;