import * as Yup from "yup";

export const validator = (fields) => {
    return fields.map(field => {
        return {
            name: field.fieldName,
            type: typeMapper(field.fieldName),
            isMandatory: field.isMandatory
        }
    })
};

const typeMapper = (type) => {
    switch (type) {
        case 'Navn': return 'name';
        case 'E-post': return 'email';
        case 'Telefon': return 'phone';
        case 'Firma': return 'company';
        default: return 'text';
    }
};

export const createValidationSchema = (fields) => {
    return fields.reduce(
        (prev, cur) => {
            if (cur.fieldName.toLowerCase() === 'navn') {
                if (cur?.isMandatory) {
                    return {
                        ...prev,
                        name: Yup.string().required('Navn mangler')
                    }
                }
                else {
                    return {
                        ...prev,
                        name: Yup.string()
                    }
                }
            }
            if (cur.fieldName.toLowerCase() === 'e-post') {
                if (cur?.isMandatory) {
                    return {
                        ...prev,
                        email: Yup.string().email('Ugyldig e-post').required('E-post mangler')
                    }
                }
                else {
                    return {
                        ...prev,
                        email: Yup.string().email()
                    }
                }
            }
            if (cur.fieldName.toLowerCase() === 'telefon') {
                if (cur?.isMandatory) {
                    return {
                        ...prev,
                        phone: Yup.string().required('Telefon mangler')
                    }
                }
                else {
                    return {
                        ...prev,
                        phone: Yup.string()
                    }
                }
            }
            if (cur.fieldName.toLowerCase() === 'firma') {
                if (cur?.isMandatory) {
                    return {
                        ...prev,
                        company: Yup.string().required('Firma mangler')
                    }
                }
                else {
                    return {
                        ...prev,
                        name: Yup.string()
                    }
                }
            }
            return prev;
        },
        {}
    );
}