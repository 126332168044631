import {Button, Container, Grid, Typography} from "@mui/material";
import AppBar from "../components/AppBar";
import urlBuilder from "../utils/urlBuilder";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Linked from "../wrappers/Linked";

const CoverImage = ({
    image,
    logos,
    menuItems,
    textColor,
    header,
    text,
    darkLogo,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
    buttonSlug,
    buttonLinkExternal,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const hotspot = image?.hotspot;
    let imgUrl;
    if (hotspot) {
        imgUrl = urlBuilder(image).url();
    }
    else {
        imgUrl = image?.asset?.url;
    }

    const hotspotX = hotspot?.x || 0;
    const hotspotY = hotspot?.y || 0;

    return (
        <Container
            maxWidth={false}
            disableGutters={true}
            sx={{
                overflow: 'hidden',
                height: '100vh',
                width: '100%',
                backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: `${hotspotX*100}%`,
                backgroundPositionY: `${hotspotY*100}%`
            }}
        >
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    height: '100%',
                    width: '100%',
                    paddingLeft: isMobile ? '0' : '5%',
                    paddingRight: isMobile ? '0' : '5%',
                }}
            >
                {
                    menuItems && <AppBar logos={logos} menuItems={menuItems} dark={darkLogo} color={textColor}/>
                }
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        height: '100%'
                    }}
                >
                    <Grid
                        item
                        md={8}
                    >
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                color: textColor?.colorCode || 'white'
                            }}
                            variant='h1'
                        >
                            {header}
                        </Typography>
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                color: textColor?.colorCode || 'white'
                            }}
                        >
                            {text}
                        </Typography>
                        <Button
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            variant="text"
                            sx={{
                                backgroundColor: buttonBackgroundColor,
                                textTransform: 'none',
                                padding: '14px',
                                marginTop: '15px',
                                borderRadius: '4px',
                                "&.MuiButtonBase-root:hover": {
                                    backgroundColor: buttonBackgroundColor
                                },
                            }}
                        >
                            <Linked
                                internalLink={buttonSlug}
                                externalLink={buttonLinkExternal}
                            >
                                <Typography
                                    fontFamily="GT-America-Black"
                                    color={buttonTextColor}
                                    fontSize={'1.2rem'}
                                >
                                    {buttonText}
                                </Typography>
                            </Linked>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
};

export default CoverImage;