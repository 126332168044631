const mapping = (gap) => {
    const gapString = gap?.toUpperCase().trim();
    switch (gapString) {
        case 'INGEN': return 0;
        case 'LITEN': return 20;
        case 'MEDIUM': return 120;
        case 'STOR': return 180;
        default: return 0;
    }
};

export default mapping;