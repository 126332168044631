import {Grid} from "@mui/material";
import {Link} from "react-router-dom";
import Menu from "../components/Menu";
import MobileMenu from "../components/MobileMenu";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AppBar = ({
    logos,
    menuItems,
    dark = false,
    color,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const logo =
        dark
            ? logos?.darkLogo
            : logos?.lightLogo;

    return (
        <Grid
            container
            sx = {{
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Grid
                item
                sx = {{
                    justifyContent: 'center',
                    maxWidth: {
                        xs: '55%',
                        md: '15%'
                    }
                }}
            >
                {logo &&
                <Link to="/">
                    <img
                        src={logo}
                        style={{
                            height: '100%',
                            width: '60%',
                            marginLeft: isMobile ? '10px' : '0px'
                        }}
                        alt="Strømmes logo"
                    />
                </Link>
                }
            </Grid>
            <Grid
                item
                sx = {{
                    justifyContent: 'center',
                }}
            >
                {
                    isMobile ? (
                        <MobileMenu
                            items = {menuItems}
                            color={color}
                        />
                    )
                    : (
                        <Menu
                            items = {menuItems}
                            color={color}
                        />
                    )
                }
            </Grid>
        </Grid>
    )
};

export default AppBar;