import {Box, Button, Typography} from "@mui/material";
import Linked from "../wrappers/Linked";

const TextWithButton = ({
    header,
    headerColor,
    text,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
    buttonSlug,
    buttonLinkExternal,
}) => {

    return (
        <Box
            sx={{
                paddingLeft: '30px',
                paddingRight: '30px',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    color: headerColor
                }}
            >
                {
                    header
                }
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    whiteSpace: 'break-spaces'
                }}
            >
                {text}
            </Typography>
            <Button
                disableElevation
                disableFocusRipple
                disableRipple
                variant="text"
                sx={{
                    backgroundColor: buttonBackgroundColor,
                    textTransform: 'none',
                    padding: '10px',
                    marginTop: '15px',
                    borderRadius: '4px',
                    border: 'none',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    "&.MuiButtonBase-root:hover": {
                        backgroundColor: buttonBackgroundColor
                    },
                }}
            >
                <Linked
                    internalLink={buttonSlug}
                    externalLink={buttonLinkExternal}
                >
                    <Typography
                        fontFamily="GT-America-Black"
                        color={buttonTextColor}
                    >
                        {buttonText}
                    </Typography>
                </Linked>
            </Button>
        </Box>
    );
};

export default TextWithButton;