import {Container, Grid, Typography} from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CustomPortableText from "../components/CustomPortableText";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NewsLetter = ({
    text,
    textColor,
    backgroundColor,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
    newsLetterUrl,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container
            sx={{
                backgroundColor: backgroundColor?.colorCode,
                padding: '2em',
            }}
        >
            <Grid
                container
                direction="row"
            >
                {
                    !isMobile && (
                        <Grid item>
                            <MailOutlineIcon
                                sx={{
                                    fontSize: '8em',
                                    opacity: '0.6',
                                    color: textColor?.colorCode || 'inherit',
                                    marginRight: '24px',
                                }}
                            />
                        </Grid>
                    )
                }
                <Grid item>
                    <Typography
                        fontFamily="GT-America-Black"
                        color={textColor?.colorCode || 'inherit'}
                    >
                        <CustomPortableText
                            value={text}
                        />
                    </Typography>
                    <Grid
                        container
                        direction="column"
                    >
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                sx={{
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    buttonText && (
                                        <Grid item>
                                            <a href={newsLetterUrl} target="_blank" rel="noopener noreferrer">
                                                <button
                                                    type="submit"
                                                    style={{
                                                        backgroundColor: buttonBackgroundColor?.colorCode,
                                                        borderRadius: '4px',
                                                        border: 'none',
                                                        paddingTop: '8px',
                                                        paddingBottom: '8px',
                                                        paddingLeft: '16px',
                                                        paddingRight: '16px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <Typography
                                                        fontFamily="GT-America-Black"
                                                        color={ buttonTextColor?.colorCode || 'inherit' }
                                                    >
                                                        {buttonText}
                                                    </Typography>
                                                </button>
                                            </a>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography
                                fontFamily="GT-America-Black"
                                color={ textColor?.colorCode || 'inherit' }
                            >
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
};

export default NewsLetter;