import {Container, Grid, Typography} from "@mui/material";
import Block from "../blocks/Block";
import ScrambledHeading from "../components/ScrambledHeading";

const DoubleBlock = ({
    header,
    headerColor,
    scrambleHeading,
    skewBlocks,
    blocks,
}) => {

    return (
        <Container disableGutters>
            {
                header && (
                    <Typography
                        variant="h3"
                        align="center"
                        sx={{
                            color: headerColor
                        }}
                    >
                        {
                            scrambleHeading
                                ? <ScrambledHeading text={header} />
                                : header
                        }
                    </Typography>
                )
            }
            <Grid
                container
                direction="row"
                spacing={0}
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    justifyContent: 'center',
                    marginTop: {
                        md: skewBlocks === 'Venstre' || skewBlocks === 'Høyre' ? '0px' : ''
                    }
                }}
            >
                {
                    blocks.map((block, index) => (
                        <Grid
                            item
                            md={6}
                            sx={{
                                width: '100%',
                                alignSelf: block._type === 'extendedImage' ? 'center' : '',
                                marginTop: {
                                    md: index === 0 && skewBlocks === 'Venstre'
                                        ? '60px'
                                        : (index === 1 && skewBlocks === 'Høyre'
                                                ? '60px'
                                                : '0px'
                                        )
                                }
                            }}
                        >
                            {
                                Block(block, index, block.animationDirection)
                            }
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    )
};

export default DoubleBlock;