import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from './theme/theme'
import {BrowserRouter} from "react-router-dom";

createRoot(
    document.getElementById('root')
).render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
