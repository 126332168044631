import React from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '300px'
};

const Map = ({
    lat,
    lng,
    apiKey
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey
    });

    const center = {
        lat,
        lng
    }

    return isLoaded ? (
        <GoogleMap
            options={{
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false
            }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
        >
            <Marker
                position={center}
            />
        </GoogleMap>
    ) : <></>
}

export default React.memo(Map);