import CoverImage from "./CoverImage";
import CoverVideo from "./CoverVideo";
import DoubleBlock from "./DoubleBlock";
import ImageGrid from "./ImageGrid";
import ContactForm from "./ContactForm";
import Locations from "./Locations";
import EmployeeCard from "./EmployeeCard";
import NewsLetter from "./NewsLetter";
import "animate.css/animate.min.css";
import FreeText from "./FreeText";
import VerticalSpace from "./VerticalSpace";
import Video from "./Video";
import SeparationLine from "./SeparationLine";

const CardFactory = (card, menuItems = null, logos = null) => {
    switch (card._type) {
        case 'coverImage': {
            return (
                <CoverImage
                    image={card.coverImage}
                    logos={logos}
                    menuItems={menuItems}
                    header={card.header}
                    text={card.text}
                    textColor={card.textColor}
                    darkLogo={card.darkLogo}
                    buttonText={card?.button?.text || ''}
                    buttonTextColor={card?.button?.textColor?.colorCode || 'inherit'}
                    buttonBackgroundColor={card?.button?.backgroundColor?.colorCode || 'inherit'}
                    buttonSlug={card.buttonLink?.subpageSlug?.current || (card.buttonLink == null ? undefined : '')}
                    buttonLinkExternal={card.buttonLinkExternal}
                />
            );
        }
        case 'coverVideo': {
            return (
                <CoverVideo
                    video={card.video}
                    logos={logos}
                    menuItems={menuItems}
                    header={card.header}
                    text={card.text}
                    textColor={card.textColor}
                    darkLogo={card.darkLogo}
                />
            )
        }
        case 'doubleBlock': {
            return (
              <DoubleBlock
                  header={card.header}
                  headerColor={card?.headerColor?.colorCode || 'black'}
                  scrambleHeading={card.scrambleHeading}
                  skewBlocks={card.skewBlocks}
                  blocks={card.blocks}
              />
            );
        }
        case 'imageGrid': {
            return (
                <ImageGrid
                    header={card.header}
                    headerColor={card?.headerColor?.colorCode || 'black'}
                    scrambleHeading={card.scrambleHeading}
                    numberOfColumns={card.noColumns}
                    images={card.images}
                    imageGap={card.imageGap}
                    animationDirection={card.animationDirection}
                />
            );
        }
        case 'contactForm': {
            return (
                <ContactForm
                    header={card.header}
                    headerColor={card?.headerColor?.colorCode || 'black'}
                    fields={card.fields}
                    mail={card.sendToMail}
                />
            );
        }
        case 'locationsCard': {
            return (
                <Locations
                    header={card.header}
                    headerColor={card?.headerColor?.colorCode || 'black'}
                />
            );
        }
        case 'employeeCard': {
            return (
                <EmployeeCard
                    locations={card.employeeLocations}
                />
            );
        }
        case 'newsLetter': {
            return (
                <NewsLetter
                    text={card.text}
                    textColor={card.textColor}
                    backgroundColor={card.backgroundColor}
                    buttonText={card.button?.text}
                    buttonTextColor={card.button?.textColor}
                    buttonBackgroundColor={card.button?.backgroundColor}
                    newsLetterUrl={card.newsLetterUrl}
                />
            );
        }
        case 'textBlock': {
            return (
                <FreeText
                    text={card.text}
                    backgroundColor={card.backgroundColor?.colorCode || 'transparent'}
                    textColor={card.textColor?.colorCode || 'inherit'}
                    animationDirection={card.animationDirection}
                />
            )
        }
        case 'verticalSpace': {
            return (
                <VerticalSpace
                    height={card.height}
                    backgroundColor={card.backgroundColor}
                />
            )
        }
        case 'video': {
            return (
                <Video
                    url={card.url}
                />
            )
        }
        case 'separationLine': {
            return (
                <SeparationLine
                    color={card.color?.colorCode || 'black'}
                />
            )
        }

        default: return <> </>;
    }
};

export default CardFactory;