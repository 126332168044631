import {Container} from "@mui/material";

const VerticalSpace = ({
    height,
    backgroundColor
}) => {

    return (
        <Container
            disableGutters
            sx={{
                height: `${height*0.5}em`,
                backgroundColor: backgroundColor?.colorCode || 'transparent'
            }}
        >
        </Container>
    )
};

export default VerticalSpace;